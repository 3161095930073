export const useCanonicalUrl = () => {
  const requestUrl = useRequestURL();
  const canonicalUrl = computed(() => {
    return `${requestUrl.protocol}//${
      requestUrl.host
    }/u${requestUrl.pathname.replace(/^\/u/, "")}`;
  });
  useHead({
    link: () => {
      return [
        {
          rel: "canonical",
          href: canonicalUrl,
        },
      ];
    },
  });
};
